import React from 'react'
import Typography, { type TypographyProps } from '~/components/Typography'

export type RadioProps = PropsWithClassName<{
  label?: React.ReactNode
  labelProps?: TypographyProps<'span'>
  testId?: string
}> &
  React.ComponentPropsWithoutRef<'input'>

const Radio = ({
  label = '',
  labelProps = undefined,
  className,
  name,
  testId,
  ...restInputProps
}: RadioProps) => {
  return (
    <label className={className}>
      <div className="flex items-center gap-x-3 sm:gap-x-2">
        <div className="relative shrink-0">
          <input
            type="radio"
            tabIndex={0}
            className="peer absolute h-0 w-0 opacity-0"
            data-testid={`${testId}RadioInput`}
            {...restInputProps}
          />
          <span className="group flex size-5 items-center justify-center rounded-full border-2 border-black/10 bg-white transition-colors peer-checked:border-black">
            <span className="block size-2 shrink-0 scale-0 rounded-full bg-black transition-transform peer-checked:group-[]:scale-100" />
          </span>
        </div>
        {label ? (
          <Typography {...labelProps} component="span">
            {label}
          </Typography>
        ) : null}
      </div>
    </label>
  )
}

export default Radio
