import React from 'react'
import Container from '~/components/Container'
import LinkButton, { type LinkButtonProps } from '~/components/LinkButton'
import Nav from '~/components/Nav'
import Typography from '~/components/Typography'

export type PageErrorProps = {
  title: string
  description: string
  LinkButtonProps: LinkButtonProps
  statusCode?: number
}

const PageError = ({
  title,
  description,
  LinkButtonProps,
  statusCode
}: PageErrorProps) => {
  return (
    <div className="flex min-h-dvh flex-col bg-black">
      <Nav color="dark" />
      <Container
        component="main"
        className="flex flex-1 flex-col items-center justify-center gap-y-12 text-white"
      >
        <div className="flex flex-col items-center justify-center gap-y-7 text-center">
          {statusCode ? (
            <Typography
              className="leading-none text-white/10 md:text-[250px]"
              component="h1"
              variant="h1"
              family="brand"
            >
              {statusCode}
            </Typography>
          ) : null}
          <div className="flex flex-col items-center gap-y-2">
            <Typography variant="h4" component="p" className="!font-bold">
              {title}
            </Typography>
            <Typography className="max-w-3xl text-balance text-white/60">
              {description}
            </Typography>
          </div>
        </div>
        <div>
          <LinkButton rounded="full" {...LinkButtonProps} />
        </div>
      </Container>
    </div>
  )
}

export default PageError
