import React from 'react'
import { useTranslation } from 'react-i18next'
import Radio, { type RadioProps } from '~/components/Radio'
import type { SlotPickWhen } from '../SlotPicker.types'

const SlotRadio = (props: RadioProps) => {
  return (
    <Radio
      className="flex w-full cursor-pointer justify-start rounded-md border border-black/10 p-6 text-black/50 focus-within:border-black has-[:checked]:border-black has-[:checked]:text-black sm:justify-center"
      labelProps={{ variant: 'body2' }}
      {...props}
    />
  )
}

export type SlotPickerWhenProps = {
  value: SlotPickWhen
  onChange: (value: SlotPickWhen) => void
}

const SlotPickerWhen = ({ value, onChange }: SlotPickerWhenProps) => {
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return onChange(event.target.value as SlotPickWhen)
  }

  return (
    <div className="flex w-full flex-col gap-4 md:flex-row">
      <SlotRadio
        checked={value === 'now'}
        onChange={handleChange}
        value={'now' as SlotPickWhen}
        name="slot-picker-when"
        label={t('booking:bookNow')}
        testId="slotPickerNow"
      />
      <SlotRadio
        checked={value === 'later'}
        onChange={handleChange}
        value={'later' as SlotPickWhen}
        name="slot-picker-when"
        label={t('booking:bookLater')}
        testId="slotPickerLater"
      />
    </div>
  )
}

export default SlotPickerWhen
