import React from 'react'
import { Calendar, type DateValue, NextUIProvider } from '@nextui-org/react'
import type { BookingSlot } from '~/@types/graphql-police'
import Button from '~/components/Button'
import { ROUTES } from '~/constants/routes'
import { useLocale } from '~/hooks/useLocale'
import { useNavigate } from '~/hooks/useNavigate'
import * as queries from '~/queries'
import { getSubscriptionLinkTo } from '~/utils/booking'
import { trackEvent } from '~/utils/tracking'
import { CalendarDate } from '@internationalized/date'
import { I18nProvider } from '@react-aria/i18n'
import { useQuery } from '@tanstack/react-query'
import type { SlotPickContext } from '../SlotPicker.types'
import { getCalendarOptions, getSlotsFromDay } from './SlotPickerNow.utils'
import SlotsHoursList from './SlotsHoursList'
import SlotsHoursLoading from './SlotsHoursLoading'

export type SlotPickerNowProps = {
  context: SlotPickContext
  isInvoiceNeeded: boolean
  isLogged: boolean
  validateButtonLabel: string
  redirectTo?: string | null
}

type State = {
  selectedDay: CalendarDate | null
  selectedSlot: BookingSlot | null
}

const SlotPickerNow = ({
  context,
  isInvoiceNeeded,
  isLogged,
  validateButtonLabel,
  redirectTo = null
}: SlotPickerNowProps) => {
  const locale = useLocale()
  const navigate = useNavigate()

  const { data: slots, isLoading } = useQuery(
    queries.getOpenBookingSlotsOptions()
  )

  const [state, setState] = React.useState<State>({
    selectedDay: null,
    selectedSlot: null
  })

  const { selectedDay, selectedSlot } = state

  const calendarOptions = React.useMemo(() => {
    return slots ? getCalendarOptions(slots) : null
  }, [slots])

  React.useEffect(() => {
    if (
      !selectedDay &&
      calendarOptions &&
      calendarOptions.calendarDaysFromSlots.length > 0
    ) {
      setState({
        selectedDay: calendarOptions.calendarDaysFromSlots[0]!,
        selectedSlot: null
      })
    }
  }, [calendarOptions, selectedDay])

  const isDateUnavailable = (date: DateValue) => {
    if (calendarOptions && selectedDay) {
      return calendarOptions.matchIsDateUnavailable(date)
    }

    return true
  }

  const handleSelectDay = (date: CalendarDate) => {
    trackEvent('SELECT_SLOT_DAY', { page: context })
    setState({
      selectedDay: date,
      selectedSlot: null
    })
  }

  const handleSelectSlot = (slot: BookingSlot) => {
    trackEvent('SELECT_SLOT_HOUR', { page: context })
    setState((prevState) => {
      return {
        selectedDay: prevState.selectedDay,
        selectedSlot: slot
      }
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!selectedDay || !selectedSlot) {
      return
    }

    if (redirectTo) {
      navigate({
        pathname: redirectTo,
        search: `?slotId=${selectedSlot.id}`
      })

      return
    }

    const linkTo = getSubscriptionLinkTo({
      pathname: isLogged ? ROUTES.subscription_payment : ROUTES.signup,
      slotId: selectedSlot.id,
      isInvoiceNeeded
    })

    navigate(linkTo)
  }

  const availableSlots =
    !isLoading && selectedDay && slots
      ? getSlotsFromDay(slots, selectedDay)
      : []

  const getCalendarLocale = () => {
    if (locale === 'en') {
      // If the user browser indicate an english locale, we use it (so it will be more precise than "en")
      if (navigator.language.startsWith('en')) {
        return navigator.language
      }

      // But if the user browser indicate another language, we use the en-UK locale (so week starts on Monday like in Paris)
      return 'en-UK'
    }

    return locale
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="grid h-full md:grid-cols-[60%,auto]">
        <div className="h-full w-full p-3">
          {/* See https://github.com/nextui-org/nextui/issues/3395 */}
          <NextUIProvider disableAnimation={false}>
            <I18nProvider locale={getCalendarLocale()}>
              <Calendar
                classNames={{
                  gridHeader: 'shadow-none',
                  title: 'text-black',
                  headerWrapper: 'px-1 pb-9',
                  cell: 'p-1.5',
                  cellButton:
                    '[&:not([data-disabled],[data-unavailable])]:border border-black/10 data-[hover=true]:bg-white data-[hover=true]:border-black data-[hover=true]:text-black',
                  nextButton: '[&:not([data-disabled])]:text-black',
                  prevButton: '[&:not([data-disabled])]:text-black',
                  gridHeaderRow: 'p-0 gap-3',
                  base: 'flex justify-center border-0 rounded-none bg-transparent shadow-none isolate m-0 p-0 data-[selected=true]:border-primary'
                }}
                isDisabled={isLoading}
                minValue={calendarOptions?.minDate}
                maxValue={calendarOptions?.maxDate}
                color="primary"
                calendarWidth="100%"
                isDateUnavailable={isDateUnavailable}
                onChange={handleSelectDay}
                value={selectedDay}
              />
            </I18nProvider>
          </NextUIProvider>
        </div>
        <div className="relative h-full w-full border-black/10 md:border-l">
          {isLoading ? (
            <SlotsHoursLoading />
          ) : (
            <SlotsHoursList
              availableSlots={availableSlots}
              onSelectSlot={handleSelectSlot}
              selectedSlot={selectedSlot}
            />
          )}
        </div>
      </div>
      <div className="border-black/10 px-8 py-6 md:border-t">
        <Button
          rounded="full"
          type="submit"
          disabled={!(selectedDay && selectedSlot)}
          color="primary"
          fullWidth
          size="lg"
          testId="validate"
        >
          {validateButtonLabel}
        </Button>
      </div>
    </form>
  )
}

export default SlotPickerNow
