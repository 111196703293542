import { WEBMARKETER_PROJECT_ID } from '~/constants/env'
import { matchIsBrowser } from '~/helpers/navigator'
import { matchIsReleasedVersion } from '~/utils/env'

export const trackWebmarketerEvent = async ({
  eventType,
  data,
  eventNonce
}: {
  eventType: string
  data: Record<string, unknown>
  eventNonce?: string
}) => {
  if (matchIsBrowser() && matchIsReleasedVersion()) {
    await fetch('https://api.webmarketer.io/api/v1/events', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        projectId: WEBMARKETER_PROJECT_ID,
        eventType,
        eventDate: new Date().toISOString(),
        trackerId: window.webmarketerTracker?.id,
        nonce: eventNonce ?? window.webmarketerTracker?.pageLoadNonce,
        data
      })
    })
  }
}

export const trackWebmarketerPageView = () => {
  if (
    matchIsBrowser() &&
    matchIsReleasedVersion() &&
    window.webmarketerTracker
  ) {
    window.webmarketerTracker.send('pageview')
  }
}
