import React from 'react'
import ReactDom from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useReducedMotion,
  useScroll,
  useWillChange
} from 'framer-motion'
import type { TFunction } from 'i18next'
import { ClientOnly } from 'remix-utils/client-only'
import Burger from '~/components/Burger'
import Container from '~/components/Container'
import ChevronBottom from '~/components/icons/ChevronBottom'
import Logo from '~/components/icons/Logo'
import SimpleLogo from '~/components/icons/SimpleLogo'
import Link, { type LinkProps } from '~/components/Link'
import Menu from '~/components/Menu'
import { NavbarGrid, useNavExtended } from '~/components/Nav/Navbar.utils'
import { ROUTES } from '~/constants/routes'
import { useHiddenNav } from '~/stores/HiddenNav/HiddenNav'
import { cn } from '~/utils/cn'
import { trackEvent } from '~/utils/tracking'
import Typography from '../Typography'
import GiftRibbon from './GiftRibbon'

type ZoiCardProps = PropsWithClassName & {
  children?: React.ReactNode
}

const ZoiCard = ({ className, children = null }: ZoiCardProps) => {
  return (
    <div
      className={cn(
        'absolute left-[60px] flex h-[180px] w-[120px] translate-y-6 items-center justify-center rounded-lg border border-white/20 bg-gradient-to-br from-[#404040] to-[#000000] text-white shadow-lg transition-transform motion-reduce:transition-none',
        className
      )}
    >
      <SimpleLogo width={24} height={24} />
      {children}
    </div>
  )
}

const GrayCard = () => {
  return (
    <div className="h-[130px] w-full rounded-md border border-opacity-[0.04] bg-black bg-opacity-[0.06]" />
  )
}

const SubscribeCard = () => {
  return (
    <div className="relative flex h-[180px] w-full items-end justify-center overflow-hidden">
      <GrayCard />
      <ZoiCard className="group-hover:translate-y-2" />
    </div>
  )
}

const CompaniesCard = () => {
  return (
    <div className="relative flex h-[180px] w-full items-end justify-center overflow-hidden">
      <GrayCard />
      <ZoiCard className="-translate-x-6 translate-y-10 -rotate-[8deg] group-hover:-translate-x-8 group-hover:translate-y-5" />
      <ZoiCard className="group-hover:translate-y-2" />
      <ZoiCard className="translate-x-6 translate-y-10 rotate-[6deg] group-hover:translate-x-7 group-hover:translate-y-5 group-hover:rotate-[10deg]" />
    </div>
  )
}

const GiftCard = () => {
  return (
    <div className="relative flex h-[180px] w-full items-end justify-center overflow-hidden">
      <GrayCard />
      <ZoiCard className="group-hover:translate-y-2">
        <GiftRibbon className="absolute top-[-1px] left-[-1px]" />
        <GiftRibbon className="absolute bottom-[-1px] right-[-1px] rotate-180" />
      </ZoiCard>
    </div>
  )
}

type Category =
  | {
      title: string
      items?: never
      to: ValuesOf<typeof ROUTES>
      testId: string
    }
  | {
      title: string
      to?: never
      testId: string
      items: {
        title: string
        component?: React.ReactNode
        to: ValuesOf<typeof ROUTES>
        testId: string
      }[]
    }

const getCategories = (t: TFunction): Category[] => {
  return [
    {
      title: t('common:theCheckUp'),
      to: ROUTES.checkup,
      testId: 'navCheckup'
    },
    {
      title: t('common:theFollowUp'),
      to: ROUTES.followup,
      testId: 'navFollowUp'
    },
    {
      title: t('common:book'),
      testId: 'navBook',
      items: [
        {
          title: t('common:forMe'),
          component: <SubscribeCard />,
          to: ROUTES.subscription_booking,
          testId: 'navForMe'
        },
        {
          title: t('common:throughCompany'),
          component: <CompaniesCard />,
          to: ROUTES.bookingCompanies_form,
          testId: 'navThroughCompany'
        },
        {
          title: t('common:forSomeoneSpecial'),
          component: <GiftCard />,
          to: ROUTES.offerGift,
          testId: 'navForSomeoneSpecial'
        }
      ]
    }
  ]
}

type NavItemProps = (
  | ({
      as: 'button'
    } & React.ComponentPropsWithoutRef<'button'>)
  | ({
      as: 'link'
    } & LinkProps)
) & {
  isBold?: boolean
} & Pick<NavProps, 'color'>

const NavItem = ({
  as: asType,
  color = 'light',
  ...restProps
}: NavItemProps) => {
  const className = cn(
    'rounded-lg inline-block py-2 px-5 cursor-pointer flex gap-x-1 items-center text-body1',
    {
      'hover:bg-gray-100': color === 'light',
      'hover:bg-white/10': color === 'dark'
    }
  )

  if (asType === 'button') {
    return (
      <button
        type="button"
        {...(restProps as React.ComponentPropsWithoutRef<'button'>)}
        className={className}
      />
    )
  }

  return <Link {...(restProps as LinkProps)} className={className} />
}

export type NavProps = PropsWithClassName<{
  color?: 'dark' | 'light'
}>

const Nav = ({ className, color = 'light' }: NavProps) => {
  const { t } = useTranslation()
  const isReducedMotion = useReducedMotion()
  const [isMenuOpened, setIsMenuOpened] = React.useState(false)
  const subMenuRef = React.useRef<HTMLDivElement>(null)
  const { hideExtendedMenu, currentActiveIndex, linkProps } = useNavExtended()
  const previousScrollYRef = React.useRef<number | null>(null)
  const willChange = useWillChange()
  const { scrollY } = useScroll()

  const { isNavHidden, setIsNavHidden } = useHiddenNav()

  useMotionValueEvent(scrollY, 'change', (currentScrollY) => {
    if (isReducedMotion) {
      return
    }

    const scrollDirection =
      previousScrollYRef.current !== null &&
      currentScrollY > previousScrollYRef.current
        ? 'down'
        : 'top'

    const newIsNavHidden =
      scrollDirection === 'down' && currentScrollY > window.innerHeight

    if (newIsNavHidden !== isNavHidden) {
      setIsNavHidden(newIsNavHidden)
    }

    previousScrollYRef.current = currentScrollY
  })

  const openMenu = () => {
    trackEvent('OPEN_BURGER_MENU')
    setIsMenuOpened(true)
  }

  const closeMenu = () => {
    setIsMenuOpened(false)
  }

  const categories = getCategories(t)

  const currentCategoryActive =
    currentActiveIndex !== null ? categories[currentActiveIndex] : null

  return (
    <motion.header
      className={cn(
        'sticky left-0 right-0 top-0 z-10 w-full border-b border-black/5',
        {
          'bg-black text-white': color === 'dark',
          'bg-white text-black': color === 'light'
        },
        className
      )}
      initial={{ y: 0 }}
      style={{ willChange }}
      transition={{ type: 'just', duration: 0.2 }}
      animate={{ y: isNavHidden ? '-100%' : 0 }}
    >
      <NavbarGrid>
        <NavbarGrid.NavbarGridItem align="start">
          <Link
            prefetch="none"
            to="/"
            aria-label={t('common:zoiHomePage')}
            testId="navHome"
            onClick={() => {
              trackEvent('LOGO_BACK_HOME_CLICK')
            }}
          >
            <Logo />
          </Link>
        </NavbarGrid.NavbarGridItem>
        <NavbarGrid.NavbarGridItem align="center">
          {categories.map((category, index) => {
            const { title, items, to, testId } = category

            return items ? (
              <NavItem
                color={color}
                as="button"
                key={title}
                {...linkProps(index)}
              >
                {title}
                <ChevronBottom />
              </NavItem>
            ) : (
              <NavItem
                color={color}
                as="link"
                key={title}
                to={to}
                testId={testId}
              >
                {title}
              </NavItem>
            )
          })}
        </NavbarGrid.NavbarGridItem>
        <NavbarGrid.NavbarGridItem className="gap-x-6" align="end">
          <Link testId="navContactUs" to={ROUTES.contactUs} underline="hover">
            <Typography component="span">{t('common:contact')}</Typography>
          </Link>
          <Burger onClick={openMenu} />
          <ClientOnly>
            {() => {
              return ReactDom.createPortal(
                <AnimatePresence>
                  {isMenuOpened ? <Menu onClose={closeMenu} /> : null}
                </AnimatePresence>,
                document.body
              )
            }}
          </ClientOnly>
        </NavbarGrid.NavbarGridItem>
      </NavbarGrid>
      {currentCategoryActive && currentCategoryActive.items ? (
        <div
          role="presentation"
          tabIndex={-1}
          ref={subMenuRef}
          className={cn(
            'absolute left-0 top-[100%] w-full shadow-[0px_5px_10px_0px_rgba(0,0,0,0.05)]',
            {
              'bg-black text-white shadow-[0px_5px_10px_0px_rgba(255,255,255,0.05)]':
                color === 'dark',
              'bg-white text-black shadow-[0px_5px_10px_0px_rgba(0,0,0,0.05)]':
                color === 'light'
            }
          )}
          onMouseLeave={hideExtendedMenu}
          onBlur={hideExtendedMenu}
          onMouseEnter={() => {
            return hideExtendedMenu.cancel()
          }}
        >
          <nav className="flex justify-center py-5">
            <Container className="max-w-4xl">
              <ul className="grid w-full grid-cols-[repeat(3,1fr)] justify-center">
                {currentCategoryActive.items.map((item) => {
                  const component = item.component ?? null

                  return (
                    <li key={item.title} className="mx-2 shrink-0">
                      <Link
                        className="flew-wrap group flex flex-col items-center gap-y-4 rounded-lg p-4"
                        to={item.to}
                        onClick={hideExtendedMenu}
                        testId={item.testId}
                      >
                        {component}
                        <Typography variant="h6" family="brand" align="center">
                          {item.title}
                        </Typography>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </Container>
          </nav>
        </div>
      ) : null}
    </motion.header>
  )
}

export default Nav
